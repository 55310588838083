module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"cuub","accessToken":"MC5ZTTJwT3hJQUFDZ0FVUkFH.77-9XQrvv73vv70hUO-_ve-_vW7vv70o77-9Ye-_ve-_ve-_vUfvv71L77-9Uu-_vVjvv73vv71CVkDvv70377-9","toolbar":"new","promptForAccessToken":true,"apiEndpoint":"https://cuub.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-80,"duration":800},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-5VJLGMH","includeInDevelopment":false,"dataLayerName":"dataLayer","routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"defaultDataLayer":null,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
